import axios from 'axios';

const axiosPublic = axios.create();

axiosPublic.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

export const setHeaders = (config, apiScope) => {
  const currentUrl = window.location.href;
  return {
    ...config,
    headers: {
      ...config.headers,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-api-scope': currentUrl.includes('app-crafter') ? 'AC' : apiScope,
    },
  };
};

export const interceptPublicRequests = (apiScope) =>
  axiosPublic.interceptors.request.use(
    (config) => setHeaders(config, apiScope),
    (error) => Promise.reject(error),
  );

export const interceptPublicResponses = ({ isAPIDown, setIsAPIDown }) =>
  axiosPublic.interceptors.response.use(
    (response) => {
      if (isAPIDown) {
        setIsAPIDown(false);
      }
      return response;
    },
    (error) => {
      // Check in response status is 500
      if (error.response.status === 500) {
        // If isAPIDown is false, set to true
        if (!isAPIDown) {
          setIsAPIDown(true);
        }
      }
      return Promise.reject(error);
    },
  );

export default axiosPublic;
